'use strict';

angular.module('enervexSalesappApp').controller('AccountCtrl', function(Auth, $scope, $stateParams, User, Account, AccountRequest, Activity, State, Todolist, Job, Todo, Discussion, AccountActivity) {
	$scope.accountId = $stateParams.accountId;
	$scope.isAdmin = Auth.isAdmin;
	$scope.isAccountOwner = Auth.isAccountOwner;
	$scope.isCommercial = function(account){
		return account && account.accountType == 'commercial'
	}
	if($scope.accountId != 'new'){
		Account.get({
			id: $stateParams.accountId
		}).$promise.then(function(res){
			$scope.account = res;
			$scope.permissions = Auth.accountPermission($scope.account);
		});
		AccountActivity.query({
			accountId: $stateParams.accountId
		}).$promise.then(function(activities){
			$scope.activities = activities;
		});

		$scope.updatesLimit = 5;
		$scope.removeUpdatesLimit = function(){
			$scope.showAllUpdates = true;
			$scope.updatesLimit = $scope.activities.length;
		}
		$scope.placeUpdatesLimit = function(){
			$scope.showAllUpdates = false;
			$scope.updatesLimit = 5;
		}
	}
});
